import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import { GameForm } from './form';
import { GameSetup } from './setup';
import { GamePlayers } from './players';
import { GameLeaderboard } from './leaderboard';

import DebriefOverview from './debrief';
import DebriefItems from './debrief_items';
import DebriefICalculations from './debrief_calculations';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
    };

    // Bindings
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    this.props.dispatch(gameActions.fetchGames(id));
  }

  render() {
    const { game, match } = this.props;
    // const { item } = this.state;
    const SetupComponent =
      game && game.data && game.data.access_type === 'lti-hbp'
        ? GameSetup
        : GameForm;

    return (
      <div>
        {game && game.data && (
          <div>
            <Route exact path={match.path} component={SetupComponent} />
            <Route
              exact
              path={match.path + '/players'}
              component={GamePlayers}
            />

            <Route
              exact
              path={match.path + '/debrief/overview'}
              component={DebriefOverview}
            />

            <Route
              exact
              path={match.path + '/debrief/items'}
              component={DebriefItems}
            />

            <Route
              exact
              path={match.path + '/debrief/calculations'}
              component={DebriefICalculations}
            />

            <Route
              exact
              path={match.path + '/debrief/leaderboard'}
              component={GameLeaderboard}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameItem };
